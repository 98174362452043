import PageError from '../components/PageError';

const Custom404 = () => {
  return (
    <PageError
      title='Page not found'
      message="We can't seem to find what you're looking for. Please check the URL and try again."
      buttonText='Go home'
      buttonHref='/'
    />
  );
};

export default Custom404;
